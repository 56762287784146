//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapState} from "vuex";

export default {
  name: 'Header',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      phone: '',
      headerLogo: '',
      mainMenuItems: [],
      mobileMenuOpen: false,
    }
  },
  computed: {
    ...mapState('shoppingCart', ['cartItemCount']),
    ...mapState('wishList', ['wishListItemCount']),
    loggedIn() {
      return this.$auth.$state.loggedIn
    },
    currentUserName() {
      // return companyName if there is if not return fullname
      return this.$auth.user && this.$auth.user.companyName
          ? this.$auth.user.companyName
          : this.$auth.user && this.$auth.user.fullname
              ? this.$auth.user.fullname : ''
    }
  },
  async mounted() {
    await this.getHeaderData()
    this.frontendGetBySessionId()
    if(this.loggedIn){
      this.getWishListItems()
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['frontendGetBySessionId']),
    ...mapActions('wishList', ['getWishListItems']),
    async getHeaderData() {
      const headerDataResult = (await this.$axios.get(`/contents/header`))
      if(headerDataResult.status !== 200) {
        this.$toast.error('Something went wrong')
        return
      }
      const [headerData] = headerDataResult.data && headerDataResult.data.details
          ? headerDataResult.data.details.sections : null
      if (headerData){
        this.phone = headerData.phone?.fieldValue?.value
        this.mainMenuItems = this.getItems(
            headerData.menu_items
        )
        this.headerLogo = headerData.header_logo?.fieldValue?.fullFileUrl
      }
    },
    getItems(items) {
      const vueObj = {}
      Object.assign(vueObj, items)
      return vueObj
    },
    signOut() {
      this.$router.push('/logout')
    }
  },
}
